// Libraries
import { Skeleton } from '@ds';

const DashboardLoading: React.ComponentType<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="flex min-h-screen flex-col bg-secondary-grey-light-2">
      {/* Static sidebar for desktop */}
      <div className="relative hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-primary-off-black">
          <div className="flex flex-1 flex-col overflow-y-auto">
            <div className="flex min-h-[64px] shrink-0 items-center space-x-2 px-4 py-3">
              <Skeleton loading size={32} variant="circle" />
              <div className="min-w-0 flex-1">
                <Skeleton loading fontSize="subtitle-2" />
              </div>
            </div>

            <div className="px-4 pt-4">
              <Skeleton loading fontSize="badge" />
            </div>

            <div className="flex-1 space-y-1 px-2 pt-5">
              <div className="flex items-center space-x-3 p-2">
                <Skeleton loading height={24} variant="rect" width={24} />
                <Skeleton loading fontSize="badge" />
              </div>
              <div className="flex items-center space-x-3 p-2">
                <Skeleton loading height={24} variant="rect" width={24} />
                <Skeleton loading fontSize="badge" />
              </div>
              <div className="flex items-center space-x-3 p-2">
                <Skeleton loading height={24} variant="rect" width={24} />
                <Skeleton loading fontSize="badge" />
              </div>
              <div className="flex items-center space-x-3 p-2">
                <Skeleton loading height={24} variant="rect" width={24} />
                <Skeleton loading fontSize="badge" />
              </div>

              <div className="p-2">
                <div className="h-px bg-primary-grey-dark" />
              </div>

              <div className="flex items-center space-x-3 p-2">
                <Skeleton loading height={12} variant="rect" width={60} />
              </div>

              <div className="flex items-center space-x-3 p-2">
                <Skeleton loading height={24} variant="rect" width={24} />
                <Skeleton loading fontSize="badge" />
              </div>

              <div className="flex items-center space-x-3 p-2">
                <Skeleton loading height={24} variant="rect" width={24} />
                <Skeleton loading fontSize="badge" />
              </div>

              <div className="flex items-center space-x-3 p-2">
                <Skeleton loading height={24} variant="rect" width={24} />
                <Skeleton loading fontSize="badge" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:pl-64">
        <main className="flex-1 p-4 sm:p-6">{children}</main>
      </div>
    </div>
  );
};

export default DashboardLoading;
