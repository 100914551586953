/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import analytics from '@analytics';
import dayjs from 'dayjs';
import LogRocket from 'logrocket';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { getCookieConsent } from '@leaf/helpers';
import {
  CompanyProfileUserRole,
  CurrentCompanyProfileUserQuery,
  CustomDomain,
  Registries,
  useCurrentCompanyProfileUserQuery,
  useCustomDomainQuery,
  useRefinitivTokenQuery,
  useRegistryImportStatusQuery,
} from '@/apollo/generated';
import DashboardLoading from '@/components/layouts/dashboard-loading';
import ErrorComponent from '@/components/utils/error-component';
import Redirect from '@/components/utils/redirect';
import { getTranslator } from '@/i18n';
import { formatError } from '@/utils/error-helpers';
import fetcher, { fetchOptions } from '@/utils/fetcher';
import { getFieldValue, RefinitivQuoteData } from '@/utils/refinitiv';
import routes from '@/utils/routes';

interface CurrentCompanyProfileUserContext {
  currentCompanyProfileUser: NonNullable<
    CurrentCompanyProfileUserQuery['currentCompanyProfileUser']
  >;
  customDomain: CustomDomain | null;
  customDomainLoading: boolean;
  fullName: string;
  hasCompanyShareholderOfferPermission: boolean;
  hub: string;
  isComputershare: boolean;
  isPremium: boolean;
  isUK: boolean;
  latestRegisterReportDate: string;
  linkedinDaysUntilExpiryString: string | null;
  liveInvestorHubBaseUrl: string;
  liveShareholderOfferId?: string | null;
  price: number | undefined;
  priceChange: number | undefined;
  profile: NonNullable<
    CurrentCompanyProfileUserQuery['currentCompanyProfileUser']
  >['profile'];
  totalShareholderOffers: number;
  translate: (
    key: string,
    params?:
      | {
          [key: string]: string | number;
        }
      | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
}

interface Props {
  children?: React.ReactNode;
  currentCompanyProfileUser: NonNullable<
    CurrentCompanyProfileUserQuery['currentCompanyProfileUser']
  >;
}

const CurrentCompanyProfileUserContext =
  createContext<CurrentCompanyProfileUserContext | null>(null);

function resolveMarketListingKey(ticker: {
  listingKey: string;
  marketKey: string;
}): string {
  const { listingKey, marketKey } = ticker;
  const lowerCaseMarketKey = marketKey.toLowerCase();

  switch (lowerCaseMarketKey) {
    case 'aqse':
      return `${listingKey}-aq`;
    case 'lse':
      return `${listingKey}-l`;
    default:
      return listingKey;
  }
}

const CurrentCompanyProfileUserProvider: React.ComponentType<Props> = ({
  children,
  currentCompanyProfileUser,
}) => {
  // useCurrentCompanyProfileUpdatedSubscription();
  const { data, loading: customDomainLoading } = useCustomDomainQuery();
  const { data: registryData } = useRegistryImportStatusQuery();

  const latestRegisterReportDate = useMemo(() => {
    if (registryData?.registryImportStatus?.latestReportDate) {
      return dayjs(registryData.registryImportStatus.latestReportDate).format(
        'DD/MM/YYYY'
      );
    }
    return '-';
  }, [registryData?.registryImportStatus?.latestReportDate]);

  const fullName = useMemo(() => {
    if (
      currentCompanyProfileUser.user.firstName &&
      currentCompanyProfileUser.user.lastName
    ) {
      return `${currentCompanyProfileUser.user.firstName} ${currentCompanyProfileUser.user.lastName}`;
    }

    return 'Anonymous';
  }, [currentCompanyProfileUser]);

  const customDomain = data?.customDomain ? data?.customDomain : null;

  const liveInvestorHubBaseUrl = useMemo(() => {
    const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';

    if (
      data?.customDomain?.customDomain &&
      data.customDomain.isVercelDomainVerified
    ) {
      return `${protocol}://${data.customDomain.customDomain}`;
    }
    const updatedMarketListingKey = resolveMarketListingKey(
      currentCompanyProfileUser.profile.ticker
    );

    return `${protocol}://${updatedMarketListingKey}.${process.env.NEXT_PUBLIC_HERMES_DOMAIN}`;
  }, [currentCompanyProfileUser, data]);

  let locale = null;

  const isUK = ['aqse', 'lse'].includes(
    currentCompanyProfileUser.profile.ticker.marketKey
  );

  if (isUK) locale = 'en-UK';
  if (currentCompanyProfileUser.profile.ticker.marketKey === 'asx')
    locale = 'en-AU';

  const translate = getTranslator(locale);

  // used false instead of null because going !linkedinDaysUntilExpiry returns true if linkedinDaysUntilExpiry == 0
  const linkedinDaysUntilExpiry =
    currentCompanyProfileUser.profile.socialConnection
      ?.linkedinSetupCompleted &&
    dayjs(
      currentCompanyProfileUser.profile.socialConnection
        ?.linkedinRefreshTokenExpiresAt
    ).diff(dayjs().startOf('day'), 'days') < 9
      ? dayjs(
          currentCompanyProfileUser.profile.socialConnection
            ?.linkedinRefreshTokenExpiresAt
        ).diff(dayjs().startOf('day'), 'days')
      : false;

  const linkedinDaysUntilExpiryString = useMemo(() => {
    if (linkedinDaysUntilExpiry !== false && linkedinDaysUntilExpiry < 0) {
      return 'expired';
    }

    if (linkedinDaysUntilExpiry !== false) {
      switch (linkedinDaysUntilExpiry) {
        case 0:
          return 'expiring today';
        case 1:
          return 'expiring in 1 day';
        default:
          return `expiring in ${linkedinDaysUntilExpiry} days`;
      }
    }
    return null;
  }, [linkedinDaysUntilExpiry]);

  /* Get price from Refinitiv ------------------------------ START ------------------------------ */
  // Move here to include `refinitivIdentificationCode` when fetching the quote
  const { data: refinitivTokenData, refetch: refinitivTokenRefetch } =
    useRefinitivTokenQuery();

  const [price, setPrice] = useState<number>();
  const [priceChange, setPriceChange] = useState<number>();

  const params = {
    listingKey: currentCompanyProfileUser.profile.ticker.listingKey,
    marketKey: currentCompanyProfileUser.profile.ticker.marketKey,
    refinitivIdentificationCode:
      currentCompanyProfileUser.profile.ticker.refinitivIdentificationCode ||
      '',
    token: refinitivTokenData?.token?.value || '',
  };

  const queryString = new URLSearchParams(params);

  const { data: quoteData, error: quoteError } = useSWR<
    RefinitivQuoteData,
    Error
  >(
    !!currentCompanyProfileUser.profile.ticker.marketKey &&
      !!currentCompanyProfileUser.profile.ticker.listingKey &&
      !currentCompanyProfileUser.profile.isUnlisted &&
      refinitivTokenData?.token?.value
      ? `/api/refinitiv/quote-lists/get-simple-data?${queryString}`
      : null,
    fetcher,
    fetchOptions
  );

  useEffect(() => {
    let ignore = false;

    if (quoteData && !ignore) {
      const cfLast = getFieldValue(quoteData, 'CF_LAST');
      const pctchng = getFieldValue(quoteData, 'PCTCHNG');

      if (typeof cfLast === 'number') {
        setPrice(cfLast);
      }

      if (typeof pctchng === 'number') {
        setPriceChange(pctchng);
      } else {
        setPriceChange(0);
      }
    }

    return () => {
      ignore = true;
    };
  }, [quoteData]);

  useEffect(() => {
    if (quoteError && quoteError.message.includes('Token expired')) {
      // Token expired, get a new token
      refinitivTokenRefetch();
    }
  }, [quoteError, refinitivTokenRefetch]);
  /* Get price from Refinitiv ------------------------------ END ------------------------------ */

  return (
    <CurrentCompanyProfileUserContext.Provider
      value={{
        currentCompanyProfileUser,
        customDomain,
        customDomainLoading,
        fullName,
        hasCompanyShareholderOfferPermission:
          currentCompanyProfileUser.profile
            .hasCompanyShareholderOfferPermission,
        hub: currentCompanyProfileUser.profile.hub,
        isComputershare:
          currentCompanyProfileUser.profile.registry ===
          Registries.Computershare,
        isPremium: currentCompanyProfileUser.profile.isPremium,
        isUK,
        latestRegisterReportDate,
        linkedinDaysUntilExpiryString,
        liveInvestorHubBaseUrl,
        liveShareholderOfferId:
          currentCompanyProfileUser.profile.liveShareholderOfferId,
        price,
        priceChange,
        profile: currentCompanyProfileUser.profile,
        totalShareholderOffers:
          currentCompanyProfileUser.profile.totalShareholderOffers,
        translate,
      }}
    >
      {children}
    </CurrentCompanyProfileUserContext.Provider>
  );
};

export function useCurrentCompanyProfileUser() {
  const context = useContext(CurrentCompanyProfileUserContext);

  if (!context) {
    throw new Error(
      'useCurrentCompanyProfileUser must be used within a CurrentCompanyProfileUserProvider'
    );
  }

  return context;
}

type Options = {
  customLoader?: React.ReactNode;
  roles?: CompanyProfileUserRole[];
};

export function withCurrentCompanyProfileUserContext(
  Component: React.ComponentType<any>,
  options: Options = {}
) {
  const WithCurrentCompanyProfileUserContext: React.ComponentType<any> = (
    props
  ) => {
    const { data, error, loading } = useCurrentCompanyProfileUserQuery({
      fetchPolicy: 'cache-and-network',
    });

    const { asPath } = useRouter();

    useEffect(() => {
      const cookieConsent = getCookieConsent();
      const analyticsAllowed = cookieConsent && cookieConsent.analytics;
      // Send user details to LogRocket only on production.
      if (
        data?.currentCompanyProfileUser?.user &&
        process.env.NODE_ENV === 'production' &&
        analyticsAllowed
      ) {
        LogRocket.identify(data.currentCompanyProfileUser.user.id, {
          companyTicker: `${data.currentCompanyProfileUser.profile.ticker.marketKey}:${data.currentCompanyProfileUser.profile.ticker.listingKey}`,
          email: data.currentCompanyProfileUser.user.email,
          isDemo: data.currentCompanyProfileUser.profile.isDemo,
          isPremium: data.currentCompanyProfileUser.profile.isPremium,
          isTrial: data.currentCompanyProfileUser.profile.isTrial,
          name: [
            data.currentCompanyProfileUser.user.firstName,
            data.currentCompanyProfileUser.user.lastName,
          ]
            .join(' ')
            .trim(),
          phone: data.currentCompanyProfileUser.user.mobileNumber ?? '',
          role: data.currentCompanyProfileUser.role ?? '',
          simulatingAdminUserId: '',
          title: data.currentCompanyProfileUser.jobTitle ?? '',
        });
      }

      // Send user details to Segment.
      if (data?.currentCompanyProfileUser?.user) {
        analytics.identify(data.currentCompanyProfileUser.user.id, {
          account_id: data.currentCompanyProfileUser.profile.id,
          company_name: data.currentCompanyProfileUser.profile.name,
          companyTicker: `${data.currentCompanyProfileUser.profile.ticker.marketKey}:${data.currentCompanyProfileUser.profile.ticker.listingKey}`,
          email: data.currentCompanyProfileUser.user.email,
          isDemo: data.currentCompanyProfileUser.profile.isDemo,
          isPremium: data.currentCompanyProfileUser.profile.isPremium,
          isTrial: data.currentCompanyProfileUser.profile.isTrial,
          name: [
            data.currentCompanyProfileUser.user.firstName,
            data.currentCompanyProfileUser.user.lastName,
          ]
            .join(' ')
            .trim(),
          phone: data.currentCompanyProfileUser.user.mobileNumber ?? '',
          role: data.currentCompanyProfileUser.role ?? '',
          simulatingAdminUserId: '',
          title: data.currentCompanyProfileUser.jobTitle ?? '',
        });
      }
    }, [data]);

    const ifIsTempFixForPAMCampaign = asPath.includes(
      '/pam/engagement/interactive-media/updates/1404'
    );

    if (data?.currentCompanyProfileUser) {
      if (
        options.roles &&
        !options.roles.includes(data.currentCompanyProfileUser.role)
      ) {
        return <ErrorComponent statusCode={403} />;
      }

      return (
        <CurrentCompanyProfileUserProvider
          currentCompanyProfileUser={data.currentCompanyProfileUser}
        >
          <Component {...props} />
        </CurrentCompanyProfileUserProvider>
      );
    }

    if (error) {
      // When suspicious cloud ip is detected
      if (formatError(error) === 'Access denied!') {
        return <ErrorComponent />;
      }
      return <ErrorComponent statusCode={500} />;
    }

    if (loading) {
      if (options.customLoader) {
        return <>{options.customLoader}</>;
      }

      return <DashboardLoading />;
    }

    if (ifIsTempFixForPAMCampaign) {
      return (
        <Redirect
          force
          href="https://investorhub.panasiametals.com/activity-updates/corporate-activities-update-7-march-2024"
        />
      );
    }

    return (
      <Redirect
        force
        href={routes.auth.login.href}
        redirectUrl={window.location.pathname}
      />
    );
  };

  return WithCurrentCompanyProfileUserContext;
}
